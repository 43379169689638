import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = new VueRouter({
    // mode: 'history',
    routes: [{
        path: "/",
        name: "index",
        redirect: '/relay',
        component: () => import("../view/index/index.vue")
    },
    {
        path: "/layout",
        name: "Layout",
        component: () => import("../view/layout/layout.vue"),
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        children: [
            {
                path: "/relay",
                name: "relay",
                component: () => import("../view/relay/relay.vue")
            },
            {
                path: "/RelayList",
                name: "RelayList",
                component: () => import("../view/RelayList/RelayList.vue")
            },
            {
                path: "/create_relay",
                name: "create_relay",
                component: () => import("../view/create_relay/create_relay.vue")
            },
            {
                path: "/relay_detail/:gid",
                name: "relay_detail",
                component: () => import("../view/RelayList/relay_detail.vue")
            },
            {
                path: "/personal",
                name: "personal",
                component: () => import("../view/personal/personal.vue")
            },
            {
                path: "/userDetail/:gid",
                name: "userDetail",
                component: () => import("../view/personal/userDetail.vue")
            },
        ]
    }]

})

// //新建路由实例
// const router = new VueRouter({
//     routes
// })

//导出路由实例，在main.js中导入使用
export default routes 