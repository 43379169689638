<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
html,
	body {
		height: 100%;
		width: 100%;
		margin: 0;
		padding: 0;
		
	}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/*滚动条整体部分,必须要设置*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

/*滚动条的轨道*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background: #ffffff;
}

/*滚动条的滑块按钮*/
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(186, 195, 203);
  background: rgba(186, 195, 203, .8);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: #b9b9b9;
  opacity: .9;
}

/*滚动条的上下两端的按钮*/
::-webkit-scrollbar-button {
  height: 0;
  width: 0;
  background-color: #dddedf;
}
.el-select-dropdown__item.selected,.el-select-dropdown__item.is-disabled,.el-select-dropdown__item{
  font-size: 16px !important;
  font-weight: bold !important;
}

</style>
