import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueClipboard from 'vue-clipboard2';
import vuetify from '@/plugins/vuetify'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, {
  size: 'small',
  zIndex: 3000,
});
Vue.config.productionTip = false
new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) },
}).$mount('#app')
